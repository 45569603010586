<template>
  <div class="ny_aboutuser">
    <div class="ny_aboutuser_bg">
      <img :src="basicData.msg_bg" alt="" style="width:100%" />
    </div>
    <div class="ny_aboutuser_nav" v-if="typeList.length != 0">
      <ul class="nav_type">
        <li v-for="(item, idx) in typeList" :key="idx" @click="active = idx">
          {{ item.name }}
        </li>
      </ul>
      <div
        class="nav_col"
        v-html="typeList[active].content"
        v-if="
          typeList[active].name != '荣誉资质' &&
            typeList[active].name != '团队风采' 
        "
      ></div>
      <ul class="nav_list" v-else>
        <li v-for="(item, idx) in typeList[active].content" :key="idx">
          <img :src="item.value" alt="" />
          <p>{{ item.key }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      basicData: {},
      typeList: [],
      active: 0
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.basicData = res.data.data.basic
          that.typeList = []
          that.typeList.push(res.data.data.公司介绍)
          that.typeList.push(res.data.data.企业文化)
          that.typeList.push(res.data.data.发展历程)
          that.typeList.push(res.data.data.荣誉资质)
          that.typeList.push(res.data.data.团队风采)
          that.typeList.forEach((el, i) => {
            if (i == 0) {
              el.name = el.js_title
              el.content = el.js_nr
            } else if (i == 1) {
              el.name = el.qy_title
              el.content = el.qy_nr
            } else if (i == 2) {
              el.name = el.fz_title
              el.content = el.fz_nr
            } else if (i == 3) {
              el.name = el.ry_title
              el.content = el.ry_img
            } else if (i == 4) {
              el.name = el.td_title
              el.content = el.td_img
            }
          })
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ny_aboutuser {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .ny_aboutuser_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .ny_aboutuser_nav {
    margin: 1rem auto;
    width: 1400px;
    .nav_type {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      li {
        width: calc(20% - 2px);
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        background-color: #3da036;
        text-align: center;
        line-height: 2.6;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
      li:hover {
        background-color: #ff2a2a;
      }
    }
    .nav_col {
      line-height: 2;
      font-size: 14px;
      /deep/div {
        max-width: 100%;
      }
      /deep/h4 {
        font-weight: 600;
        width: 100%;
        span {
          font-size: 30px;
        }
      }
    }
    .nav_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: -0.2rem;
      li {
        margin: 0 5px;
        width: calc(20% - 10px);
        margin-top: 0.2rem;
        cursor: pointer;
        img {
          border: 1px solid #ddd;
          padding: 2px;
          width: calc(100% - 5px);
        }
        p {
          text-align: center;
          margin: 0.15rem 0;
          font-size: 14px;
        }
      }
      li:hover {
        color: #3da036;
        img {
          border-color: #3da036;
        }
      }
    }
  }
}
</style>
